(function () {
  "use strict";

  angular.module("lariAidApp").config(stateConfig);
  stateConfig.$inject = ["$stateProvider", "APP"];
  function stateConfig($stateProvider, APP) {
    $stateProvider.state("dnaInformation", {
      parent: "profileForm",
      url: "/:investigationFormId/dnaInformation",
      data: {
        authorities: APP.GLOBAL.USER_GROUP
      },
      params: {
        investigationFormId: null
      },
      views: {
        dynamicViewForInvestigationForm: {
          templateUrl: "app/investigation/prospects/profile/investigation-form/dna-information/dna.information.html",
          controller: "DNAInformationController",
          controllerAs: "vm"
        }
      },
      resolve: {
        mainTranslatePartialLoader: [
          "$translate",
          "$translatePartialLoader",
          function ($translate, $translatePartialLoader) {
            if (localStorage && localStorage.getItem("lari_currentVersion") != null) {
              $translatePartialLoader.addPart(
                "dnaInformationQuestion.json?version=" + localStorage.getItem("lari_currentVersion")
              );
            } else {
              $translatePartialLoader.addPart("dnaInformationQuestion.json?timestamp=" + new Date().getTime());
            }
            return $translate.refresh();
          }
        ],
        sectionIndex: [
          "investigationFormIndexService",
          "$timeout",
          function (investigationFormIndexService, $timeout) {
            investigationFormIndexService.setInvestigationValue("dnaInfoForm");
            /*$timeout(function() {
                	    angular.element('#profile').triggerHandler('click');
                	 });*/
          }
        ]
      }
    });
  }
})();
