(function () {
  "use strict";

  var lariDevApp = angular.module("lariAidApp");
  lariDevApp.controller("DNAInformationController", DNAInformationController);

  DNAInformationController.$inject = [
    "$scope",
    "$window",
    "$mdSidenav",
    "Principal",
    "$sessionStorage",
    "StorageDelete",
    "$state",
    "$mdMedia",
    "$cookies",
    "APP",
    "DNAInformationService",
    "InvestigationFormService",
    "$stateParams",
    "$timeout",
    "$mdDialog",
    "$rootScope"
  ];
  function DNAInformationController(
    $scope,
    $window,
    $mdSidenav,
    Principal,
    $sessionStorage,
    StorageDelete,
    $state,
    $mdMedia,
    $cookies,
    APP,
    DNAInformationService,
    InvestigationFormService,
    $stateParams,
    $timeout,
    $mdDialog,
    $rootScope
  ) {
    var vm = this;
    vm.app = APP;
    $scope.dna = {};
    $scope.dna.sections = [];
    vm.profileForm = $stateParams;
    vm.myDate = new Date();
    var oldFormData = {};
    $scope.disableDnaSaveButton = {};
    $scope.disableDnaSaveButton.isDisabled = false;
    $scope.nullDateOfTest = false;
    $scope.nullDateOfTestRecived = false;
    $scope.nullDnaCenterId = false;

    /**
     * To get Current date
     */
    vm.maxDate = new Date(vm.myDate.getFullYear(), vm.myDate.getMonth(), vm.myDate.getDate());

    vm.loadDNAFormDetails = function () {
      $scope.loadingDNA = true;
      vm.getDNAinformation();
    };

    /* Method name           : $scope.enableSave
     * Method Description 	 : This method is used to save validation in DNA form
     * @author Dinesh Sekar
     */
    $scope.enableSave = function (field, value) {
      if (field == "dnaTestInThisCase") {
        if (value != null && value != undefined) {
          $scope.disableDnaSaveButton.isDisabled = false;
          $scope.nullDateOfTest = false;
          $scope.nullDateOfTestRecived = false;
          $scope.nullDnaCenterId = false;
        }
      }

      if (field == "dnaConducted") {
        if (value != null && value != undefined) {
          $scope.disableDnaSaveButton.isDisabled = false;
          $scope.nullDateOfTest = false;
          $scope.nullDateOfTestRecived = false;
          $scope.nullDnaCenterId = false;
        }
      }

      if (field == "dnaTest") {
        if (value != null && value != undefined) {
          $scope.disableDnaSaveButton.isDisabled = false;
        }
      }

      if (field == "dnaCenter") {
        if (value != null && value != undefined) {
          $scope.disableDnaSaveButton.isDisabled = false;
        }
      }

      if (field == "dnaTestRecived") {
        if (value != null && value != undefined) {
          $scope.disableDnaSaveButton.isDisabled = false;
        }
      }
    };

    vm.getDNAinformation = function () {
      $scope.entityObj.searchKey = null;
      var sectionKey = vm.app.REF_TERM.DNA;
      var formId = $scope.investigationFormId;
      var data = InvestigationFormService.getInvestigationFormBySectionId(sectionKey, formId);
      data.then(
        function (response) {
          if (response.formVersion != null) {
            $scope.formVersion = response.formVersion;
          }
          if (response.sectionId != null) {
            $scope.dnaFormSectionId = response.sectionId;
          }
          if (response.formDTO == null) {
            var tempObj = {};
            tempObj.questions = [];
            $scope.dna.sections = [];
            $scope.dna.sections.push(tempObj);
          } else if (response.formDTO.sections.length == 0) {
            if (response.formDTO.summary) {
              var tempObj = {};
              tempObj.questions = [];
              $scope.dna.sections.push(tempObj);
              $scope.dna.summary = response.formDTO.summary;
            } else {
              var tempObj = {};
              tempObj.questions = [];
              $scope.dna.sections.push(tempObj);
            }
          } else {
            $scope.dna = response.formDTO;
          }
          //Set the object to restrict the initial save call
          oldFormData = angular.copy(response.formDTO);
          $scope.loadingDNA = false;

          $scope.updateLastEditTime(response.updatedOn);
        },
        function (reason) {
          return reason;
        }
      );
    };
    vm.removeDNAForm = function (ev, id, indexValue) {
      $scope.indexVal = indexValue;
      $mdDialog.show({
        contentElement: angular.element("#" + id),
        targetEvent: ev,
        clickOutsideToClose: false,
        escapeToClose: false
      });
    };

    vm.closeConform = function (index) {
      $mdDialog.hide();
      $scope.dna.sections.splice(index, 1);
      vm.collapseAll(index - 1, 1);
    };

    vm.closeCancel = function () {
      $mdDialog.cancel();
    };

    /* Method name           : collapseAll()
     * Method Description 	 : This method is used to collapse the accordion
     */
    vm.collapseAll = function (index, flag) {
      var height = 0;
      for (var i = 0; i < $scope.dna.sections.length; i++) {
        if (flag == 0) {
          height = height + $(".acc_" + index).closest(".md-accordion").offsetHeight + 42;
        } else {
          height = height + $(".acc_" + index).closest(".md-accordion")[0].offsetHeight + 42;
        }
        if (i == index) {
          if (!$scope.dna.sections[i].expanded) {
            $scope.dna.sections[i].expanded = !$scope.dna.sections[i].expanded;
            $("#dnaInformation").animate({ scrollTop: height }, "slow");
          } else {
            $scope.dna.sections[i].expanded = !$scope.dna.sections[i].expanded;
          }
        } else {
          $scope.dna.sections[i].expanded = false;
        }
      }
    };
    vm.addAdditionalDNAForm = function () {
      var tempObj = {};
      tempObj.expanded = false;
      tempObj.questions = [];
      $scope.notApproved = false;
      $scope.dna.sections.push(tempObj);
      vm.collapseAll($scope.dna.sections.length - 1, 0);
    };
    vm.nextInvestigationForm = function () {
      //$scope.saveAnalystFormTypeCheckList('birthMedical');
      $scope.saveData = true;
      var saveDataObj = {};
      saveDataObj.formDTO = angular.copy($scope.dna);
      //For DNA CR vaildate the required field in this method while save the DNA form
      if (
        saveDataObj.formDTO.sections[0].questions[0] != undefined &&
        saveDataObj.formDTO.sections[0].questions[0].dnaInformation_1.answer == "answer_yes"
      ) {
        if (saveDataObj.formDTO.sections[0].questions[0].dnaInformation_1a_1.answer != null) {
          saveDataObj.dateOfTest = saveDataObj.formDTO.sections[0].questions[0].dnaInformation_1a_1.answer;
        } else {
          saveDataObj.dateOfTest = null;
        }
        if (saveDataObj.formDTO.sections[0].questions[0].dnaInformation_1a_2.answerId != null) {
          saveDataObj.dnaCenterId = saveDataObj.formDTO.sections[0].questions[0].dnaInformation_1a_2.answerId;
        } else {
          saveDataObj.dnaCenterId = null;
        }
        if (saveDataObj.formDTO.sections[0].questions[0].dnaInformation_1a_9.answer != null) {
          saveDataObj.dateOfTestRecived = saveDataObj.formDTO.sections[0].questions[0].dnaInformation_1a_9.answer;
        } else {
          saveDataObj.dateOfTestRecived = null;
        }
        if (
          (saveDataObj.dateOfTest != null || saveDataObj.dateOfTest != undefined) &&
          (saveDataObj.dnaCenterId != null || saveDataObj.dnaCenterId != undefined) &&
          (saveDataObj.dateOfTestRecived != null || saveDataObj.dateOfTestRecived != undefined)
        ) {
          $scope.saveDNAFormDetails();
          //$state.go("residencyQuestions");
          $rootScope.warningPopup.show = false;
          $scope.sectionNavigationFunc(vm.app.INVESTIGATION_FORM.RESIDENCY_FORM);
          $scope.updateLastEditTime(null);
        } else {
          if (saveDataObj.dateOfTest == null || saveDataObj.dateOfTest == undefined) {
            $scope.nullDateOfTest = true;
          }
          if (saveDataObj.dnaCenterId == null || saveDataObj.dnaCenterId == undefined) {
            $scope.nullDnaCenterId = true;
          }
          if (saveDataObj.dateOfTestRecived == null || saveDataObj.vdateOfTestRecived == undefined) {
            $scope.nullDateOfTestRecived = true;
          }
          $scope.disableSaveButton.isDisabled = true;
        }
      } else {
        $scope.saveDNAFormDetails();
        //$state.go("residencyQuestions");
        $rootScope.warningPopup.show = false;
        $scope.sectionNavigationFunc(vm.app.INVESTIGATION_FORM.RESIDENCY_FORM);
        $scope.updateLastEditTime(null);
      }
    };
    /* Method name           : saveDNAFormDetails()
     * Method Description 	 : This method saves DNA form details
     */
    $scope.saveDNAFormDetails = function () {
      var isPutJsonSimilar = false;
      $rootScope.warningPopup.show = false; //To avoid showing popup while moving to other section if there is no edit in current section
      var saveDataObj = {};
      saveDataObj.formDTO = angular.copy($scope.dna);
      saveDataObj.formVersion = $scope.formVersion;
      saveDataObj.investigationFormId = $scope.investigationFormId;
      saveDataObj.sectionId = $scope.dnaFormSectionId;
      for (var i = 0; i < saveDataObj.formDTO.sections.length; i++) {
        if (!saveDataObj.formDTO.sections[i].questions) {
          saveDataObj.formDTO.sections.splice(i, 1);
          i++;
        } else if (saveDataObj.formDTO.sections[i].questions.length == 0) {
          saveDataObj.formDTO.sections.splice(i, 1);
          i++;
        } else {
          delete saveDataObj.formDTO.sections[i].expanded;
          saveDataObj.formDTO.sections[i].sortOrder = i + 1;
        }
      }

      /*	 Set the temporary object to avoid unwanted save call. 
	      			 Fist condition to avoid the initial save call for empty form 
	      			 Second condition to avoid the initial save call if the section has any form data 
		     	 */
      if (
        angular.isUndefined($scope.tempDNAObj) &&
        saveDataObj.formDTO.sections.length == 0 &&
        oldFormData == null &&
        (saveDataObj.formDTO.summary.comment == null || saveDataObj.formDTO.summary.comment == undefined)
      ) {
        $scope.tempDNAObj = angular.copy(saveDataObj);
      } else if (angular.isUndefined($scope.tempDNAObj) && angular.equals(saveDataObj.formDTO, oldFormData)) {
        $scope.tempDNAObj = angular.copy(saveDataObj);
      } else if (angular.isUndefined($scope.tempDNAObj) && !angular.equals(saveDataObj.formDTO, oldFormData)) {
        $scope.tempDNAObj = {};
        $scope.tempDNAObj.formDTO = angular.copy(oldFormData);
      }

      if (!angular.isUndefined($scope.tempDNAObj)) {
        if (angular.equals(JSON.stringify(saveDataObj.formDTO), JSON.stringify($scope.tempDNAObj.formDTO))) {
          isPutJsonSimilar = true;
        }
      }
      if ($scope.isOwner) {
        $scope.tempDNAObj = angular.copy(saveDataObj);
      } else {
        $scope.tempDNAObj = undefined; //Every time temporary object will be newly updated for non owner
      }
      
      if(saveDataObj.formDTO.sections[0].questions[0].dnaInformation_1a_2.answer == "") {
        saveDataObj.formDTO.sections[0].questions[0].dnaInformation_1a_2 = {}
       }
    
      /*saveData flag - to ensure the save button is clicked in form*/
      if (!isPutJsonSimilar) {
        if ($scope.isOwner || $scope.saveData) {
          var data = InvestigationFormService.saveInvestigationForm(saveDataObj);
          data.then(
            function (response) {
              if ($state.current.name === vm.app.INVESTIGATION.STATE.DNA_INFORMATION_STATE) {
                $scope.updateLastEditTime(response.updatedOn);
              }
              $scope.uploadFileStatusUpdated();
            },
            function (reason) {
              return reason;
            }
          );
        }
        $rootScope.warningPopup.show = true;
      }
    };

    /* File Upload Sections */

    vm.uploadFileForSummary = function (isSummary, accordionIndex, idName, ev) {
      $("#dnaSectionSummary").val(null);
      $("#dnaSectionSummary").trigger("click");
      $("#dnaSectionSummary")
        .off("change")
        .on("change", function () {
          var file = this.files[0];
          var sectionId = $scope.dnaFormSectionId;
          $scope.setOrginSectionFileUpload(vm.app.REF_TERM.DNA);
          $scope.uploadFileInSectionSummary(ev, file, vm.app.INVESTIGATION_FORM.SUMMARY_KEY, sectionId, isSummary);
        });
    };

    $rootScope.warningPopup.show = false;
    /* Method Description 	: This method triggers auto save for birth certificate form */
    $scope.$watch("dnaAutoSave", function (newValue, oldValue) {
      if (newValue != undefined && newValue != oldValue) {
        if (newValue && $scope.isOwner) {
          if ($scope.formVersion && !$scope.isreadonly) {
            $scope.saveDNAFormDetails();
            $rootScope.dnaAutoSave = false;
          } else {
            $rootScope.dnaAutoSave = false;
          }
        } else {
          $rootScope.dnaAutoSave = false;
        }
      }
    });

    /**
     * Add the requested Entity
     *
     */
    $rootScope.dnaEntityRequestsent = function (entity, locationObject, response) {
      var entityName =
        entity.entityName + ", " + locationObject.cityObj.cityTxt + ", " + locationObject.countryObj.countryTxt;
      $scope.dna.sections[0].questions[0].dnaInformation_1a_2.answer = entityName;
      $scope.dna.sections[0].questions[0].dnaInformation_1a_2.answerId = response.id;
      $scope.dna.sections[0].questions[0].dnaInformation_1a_2.requested = true;
      document.querySelector(".global-search-input input").value = entityName;
    };

    //Method for add or suggest entity
    vm.entityRequestSuggest = function (searchKey, entityName) {
      return $scope.entityRequestAddorSuggest(searchKey, entityName);
    };

    //It will get triggered while exiting from current state
    $scope.$on("$destroy", function () {
      if (!$scope.isreadonly && $scope.isOwner) {
        $scope.saveDNAFormDetails();
      }
      $rootScope.warningPopup.show = false;
      $scope.uploadedFile = false;
    });
  }
})();
