(function () {
    'use strict';
    // DO NOT EDIT THIS FILE, EDIT THE GULP TASK NGCONSTANT SETTINGS INSTEAD WHICH GENERATES THIS FILE
    angular
        .module('lariAidApp')
        .constant('VERSION', "1.9.27.2")
        .constant('BASE_URL', "https://api.lariaiddev.mlb.com/")
        .constant('OKTA_SAML_URL', "https://mlbtest.okta.com/home/mlbtest_lariaiddevsaml_1/0oaqceaywxYTo3xJ81t7/alnqceib05fyA9NmY1t7")
        .constant('DEBUG_INFO_ENABLED', false)
;
})();
